import Layout from "./App";
import Main from "./pages/Main";
import Work from "./pages/Work"
import About from "./pages/About";
import Contact from "./pages/Contact";
import Critiques from "./pages/Critiques";
export default [
    {
        path:'/',
        element:<Layout />,
        children:[
            {
                path:'/',
                element:<Main />
            },
            {
                path:'works/:id',
                element:<Work />,
            },
            {
                path:'critiques/*',
                element: <Critiques />
            },
            {
                path:'profile',
                element: <About />
            },
            {
                path:'contact',
                element: <Contact />
            }
        ]
    }
]

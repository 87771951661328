import "../styles/contact.scss"
import profile from "../assets/profile.png"
import {useOutletContext} from "web-core/hooks"
import {useEffect} from "react";
const Contact = ()=>{
    const [load,setBlack,setHeaderWhite,setLeftVisible] = useOutletContext()
    useEffect(()=>{
        setBlack(false)
        setHeaderWhite(false)
        setLeftVisible(true)
    },[])
    return (
        <div className={'contact'}>
            <img src={profile} alt=""/>
            <div>
                <div>
                    <h4>EMAIL.</h4>
                    <a target={'_blank'} href={'mailto:gpjj659399@naver.com'}>gpjj659399@naver.com</a>
                    <h4 style={{marginTop:'25px'}}>INSTAGRAM.</h4>
                    <a target={'_blank'} href={'https://www.instagram.com/gwangmi_kim/'}>@gwangmi_kim</a>
                </div>
            </div>
        </div>
    )
}
export default Contact

import "../styles/critiques.scss"
import {useOutletContext,useParams} from "web-core/hooks"
import {useEffect, useMemo, useState} from "react";
import documents from "../assets/documents";
import close from "../../src/assets/right-b.svg"
const boards = documents.critiques
const Critiques = ()=>{
    const [load,setBlack,setHeaderWhite,setLeftVisible] = useOutletContext()
    const params = useParams()
    const [current,setCurrent]=useState(null)
    useEffect(()=>{
        setBlack(true)
        setHeaderWhite(true)
        setLeftVisible(true)
    },[])
    useEffect(()=>{
        if(params['*']){
            let find = boards.findIndex(v=>v.menu===params['*'])
            setCurrent(find)
            let container = document.querySelector('.critiques')
            container.scrollTo(0,0)
        }
    },[params])
    const currentPost = useMemo(()=>{
        if(current!==null){
            return boards[current]
        }
    },[current])
    useEffect(()=>{
        let container = document.querySelector('.critiques')
        container.scrollTo(0,0)
    },[currentPost])
    return (
        <div className={'critiques'}>
            {
                current!==null?
                    <div>
                        <h1>{currentPost.title}</h1>
                        <h2>{currentPost.author}</h2>
                        <h3>{currentPost.sub}</h3>
                        <p dangerouslySetInnerHTML={{__html:currentPost.content.replace(/\n/g,'<br />')}}>
                        </p>
                        <img onClick={()=>setCurrent(null)} src={close} alt="close"/>
                    </div>
                    :
                    <table>
                        <tbody>
                        {
                            boards.map((v,k)=>{
                                return (
                                    <tr key={v.title} onClick={()=>setCurrent(k)}>
                                        <th>
                                            {v.title}
                                        </th>
                                        <td>
                                            {v.author}
                                        </td>
                                        <td>
                                            {v.sub}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
            }
        </div>
    )
}
export default Critiques

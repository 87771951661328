import "../styles/layout.scss"
import {useEffect, useMemo, useRef, useState} from "react";
import close from "../assets/close.svg";
import mobile from "../assets/mobilemenu.svg"
import closeW from "../assets/close-w.svg"
import {Outlet,Link} from "web-core"
import {useLocation} from "web-core/hooks"
import documents from "../assets/documents";
const projects = documents.works
const Layout = ({load})=>{
    const location = useLocation()
  const [modal,setModal]=useState(false)
  const [black,setBlack]=useState(false)
    const [headerWhite,setHeaderWhite]=useState(false)
    const [leftVisible,setLeftVisible]=useState(true)
    const [show,setShow]=useState(false)
    const computedClassName = useMemo(()=>{
        return [
            headerWhite?'white':null,
            show?'show':null
        ].filter(v=>v).join(' ')
    },[headerWhite,show])
    useEffect(()=>{
        setShow(false)
    },[location])
  return (
      <div className={!black?'container':'container black'}>
          {
              location.pathname!=='/'?
                  <div className={'mobile-header'}>
                      <h1>{location.pathname.split('/')[1]}</h1>
                  </div>
                  :null
          }
          <header className={computedClassName}>
              <ul className={'top'}>
                  <li>
                      <Link to={'/'}>home</Link>
                  </li>
                  <li className={location.pathname.indexOf('/works')!==-1?'active':null}>
                      <div>
                          <div>

                          </div>
                      </div>
                      <span>works</span>
                      <ul>
                          {
                              documents.works.map(v=>{
                                  return (
                                      <li key={v.id}>
                                          <Link to={'/works/'+v.id}>{v.name}</Link>
                                      </li>
                                  )
                              })
                          }
                      </ul>
                  </li>
                  <li className={location.pathname.indexOf('/critiques')!==-1?'active':null}>
                      <div>
                          <div>

                          </div>
                      </div>
                      <Link to={'/critiques'}>critiques</Link>
                      <ul>
                          {
                              documents.critiques.filter(v=>v.menu).map(v=>{
                                  return (
                                      <li key={v.title}>
                                          <Link to={'/critiques/'+v.menu}>{v.menu}</Link>
                                      </li>
                                  )
                              })
                          }
                      </ul>
                  </li>
                  <li className={location.pathname.indexOf('/profile')!==-1?'active':null}>
                      <div>
                          <div>

                          </div>
                      </div>
                      <Link to={'profile'}>profile</Link>
                      <ul id={'profile'}>
                          <li>
                              <Link to={'/profile#solo'}>개인전</Link>
                          </li>
                          <li>
                              <Link to={'/profile#group'}>기획 초대전 및 그룹전</Link>
                          </li>
                          <li>
                              <Link to={'/profile#projects'}>프로젝트 기획</Link>
                          </li>
                          <li>
                              <Link to={'/profile#art'}>비엔날레 | 아트페어 | 옥션</Link>
                          </li>
                          <li>
                              <Link to={'/profile#sponsor'}>작품소장 및 협찬</Link>
                          </li>
                      </ul>
                  </li>
                  <li className={location.pathname.indexOf('/contact')!==-1?'active':null}>
                      <div>
                          <div>

                          </div>
                      </div>
                      <Link to={'contact'}>contact</Link>
                  </li>
                  <li className={'mobile'}>
                      <a target={'_blank'} href="https://www.instagram.com/gwangmi_kim/">instagram</a>
                  </li>
              </ul>
              <p className={'footer'}>
                  ©GwangMi Kim. All rights reserved. <br/>
                  Website created by JHJ studio & Data Heroes.
              </p>
          </header>
        <ul className={'left'} style={{visibility:(leftVisible)?'visible':'hidden'}}>
          <li>
            <a href="https://www.instagram.com/gwangmi_kim/" target={'_blank'}>instagram</a>
          </li>
          {/*<li>*/}
          {/*  <a href="#" onClick={()=>setModal(true)}>kr</a>*/}
          {/*</li>*/}
        </ul>
          <Outlet context={[load,setBlack,setHeaderWhite,setLeftVisible]} />
        {
          (modal)?
              <div className={'modal'}>
                <div>
                  <img src={close} alt="" onClick={()=>setModal(false)}/>
                  <p>
                    Sorry for incovenience. <br/>
                    This section is under renovation.
                  </p>
                  <div>

                  </div>
                </div>
              </div>
              :null
        }
        <img className={'menu'} src={show?closeW:mobile} alt="menu" onClick={()=>setShow(!show)}/>
      </div>
  )
}
export default Layout

import { createBrowserRouter } from "react-router-dom";
import axios from 'axios';
export function initContext() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [{
    path: '/',
    element: "Please input router"
  }];
  var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    router: createBrowserRouter(router),
    style: style
  };
}
export { axios };
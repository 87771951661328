import Loading from "./pages/Loading";
import Layout from "./pages/Layout";
import "./styles/main.scss"
import {useState} from "react";

function App() {
  const [loaded,setLoaded]=useState(false)
  return (
    <div>
      <Loading onLoad={()=>setLoaded(true)} />
      <Layout load={loaded}/>
    </div>
  );
}

export default App;

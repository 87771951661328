import "../styles/loading.scss"
import logo from "../assets/logo.svg"
import {useEffect, useState} from "react";
import {axios} from "web-core/functions";
const preLoads = [
    'https://drive.google.com/uc?id=1D5oZAeWb5US8DrHSdVRPeU3N6uM9oaa2&export=download&confirm=t',
    'https://drive.google.com/uc?id=1ELjD4LeU1mMrTWK2Z-3oa1h2OZ_oR-Dl&export=download'
]
const Loading = ({onLoad})=>{
  const [loadeds,setLoadeds] = useState(0)
  useEffect(()=>{
    setTimeout(()=>{
      if(loadeds<150){
        setLoadeds(loadeds+10)
      }else{
        onLoad(true)
      }
    },500)
  },[loadeds,onLoad])
  return (
      <div id={'loading'} style={{display:(loadeds<150)?'flex':'none',opacity:(loadeds<120)?1:0}}>
        <img src={logo} alt=""/>
        <div className={'bar'}>
          <div className={'fill'} style={{width:loadeds+'%'}}>

          </div>
        </div>
      </div>
  )
}
export default Loading

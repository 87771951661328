import "../styles/main.scss"
import {useEffect, useMemo, useRef, useState} from "react";
import logo from "../assets/logo.svg"
import down from "../assets/down.svg"
import downW from "../assets/down-w.svg"
import right from "../assets/right.svg"
import rightB from "../assets/right-b.svg"
import close from "../assets/close.svg";
import mobile from "../assets/mobilemenu.svg"
import muteW from "../assets/mute-w.svg"
import playW from "../assets/play-w.svg"
import pauseW from "../assets/pause-w.svg"
import muteOW from "../assets/muteoff-w.svg"
import {useOutletContext} from "web-core/hooks"
import {Link} from "web-core"
const sections = [
  {
    type:'center',
    img:require('../paints/image5.png'),
    title:'불;다',
    desc:'2022, Mixed media on canvas, 65.1-90.9 cm',
    link:'1zMY05iBeSqTp7Gz_MZubKc2tBVlmmEPz'
  },
  {
    type:'left',
    img:require('../paints/image8.png'),
    title:'心 ㅡ 풍경',
    desc:'2018, Mixed media on canvas, lithography, 80.3-116.8 cm',
    link:'1mQD96Ai6xZLb0Oe7Be2QX1qq2XWtZwNh'
  },
  {
    type:'right',
    img:require('../paints/image7.png'),
    title:'삶 ㅡ 풍경...愛',
    desc:'2014, Acrylic,mixed media on canvas, lithograph, 73.0-73.0 cm',
    link:'1JcllY4vcio5mzS72UOBhZystPho4krYe'
  },
  {
    type:'center',
    img:require('../paints/image9.png'),
    title:'삶 ㅡ 바라보고... 바라보기',
    desc:'2010, Acrylic,mixed media on MDF, lithograph, 75.0-47.0 cm',
    link:'1m3Dy2GPWEhTR4a-xdwIqo0t9jX7ea7pM'
  }
]
const Main = ()=>{
  const videoRef = useRef()

  const [scroll,setScroll]=useState(0)
  const [modal,setModal]=useState(false)
  const [mute,setMute] = useState(true)
  const [paused,setPaused] = useState(false)
  const currentSection = useMemo(()=>{
    let deviceHeight=window.innerHeight
    return Math.round(scroll/deviceHeight)
  },[scroll])
  const scrollRef = useRef()
  const [load,setBlack,setHeaderWhite,setLeftVisible] = useOutletContext()
  useEffect(()=>{
    setHeaderWhite(false)
    setLeftVisible(true)
  },[])
  useEffect(()=>{
    console.log(paused,load)
    if(load){
      if(paused){
        videoRef.current.pause()
      }else{
        videoRef.current.play()
      }
    }
  },[paused,load])
  useEffect(()=>{
    if(load){
      videoRef.current.play()
    }
  },[load])
  useEffect(()=>{
    setBlack(currentSection%2===1)
  },[currentSection])
  const onScroll = ()=>{
    setScroll(scrollRef.current.scrollTop)
  }
  const scrollTo = (k)=>{
    let deviceHeight=window.innerHeight
    scrollRef.current.scrollTo({
      top:k*deviceHeight,
      behavior:'smooth'
    })
  }
  const nextScroll = ()=>{
    if(lastSection){
      scrollTo(0)
    }else{
      let deviceHeight=window.innerHeight
      scrollTo(Math.round(scroll/deviceHeight)+1)
    }
  }
  const lastSection = useMemo(()=>{
    return currentSection===sections.length+1
  },[currentSection])
  return (
      <div className={'mainContainer'} ref={scrollRef} onScroll={onScroll}>
        <section className={'first'}>
          <video preload={'auto'} src={process.env.PUBLIC_URL + '/video.mp4#t=1'} autoPlay={false} loop muted={mute || !load} controls={false} playsInline ref={videoRef} />
          <img className={'logo'} src={logo} alt=""/>
          {
            videoRef.current?
                paused?
                    <img className={'play'} src={playW} alt="" onClick={()=>setPaused(false)}/>
                    :
                    <img className={'play'} src={pauseW} alt="" onClick={()=>setPaused(true)}/>
                :null
          }
        </section>
        <section className={'second'}>
          <p>
            나의 작업들은 <b>존재의미</b>에 대한 <br/>
            <b>자아성찰</b>의 행위를 드러내고 있다.
          </p>
          <p>
            <b>사고된 붓질</b>의 행위들은 세상과<br/>
            나 자신이 살아가는 <b>숨 소리</b>이다.
          </p>
        </section>
        {
          sections.map((v,k)=>{
            return (
                <section className={v.type} key={v.title}>
                  <img src={v.img} alt={v.title}/>
                  <div>
                    <h2>{v.title}</h2>
                    <p>{v.desc}</p>
                    <Link to={'/works/'+v.link}>
                      explore more
                      {
                        (k%2===0)?
                            <img src={right} alt=""/>
                            :
                            <img src={rightB} alt=""/>
                      }
                    </Link>
                  </div>
                </section>
            )
          })
        }
        <ul className={'right'}>
          <li className={currentSection===0?'active':null} onClick={()=>scrollTo(0)}>

          </li>
          <li className={currentSection===1?'active':null} onClick={()=>scrollTo(1)}>

          </li>
          {
            sections.map((v,i)=>{
              let k = i+2
              return (
                  <li className={currentSection===k?'active':null} onClick={()=>scrollTo(k)} key={v.title}>

                  </li>
              )
            })
          }
          <li className={(currentSection<2)?'scroll center':'scroll'}>
              <span onClick={nextScroll}>
                {
                  (currentSection%2===0)?
                      <img className={lastSection?'reverse':null} src={downW} alt=""/>
                      :
                      <img className={lastSection?'reverse':null} src={down} alt=""/>
                }
                {
                  (lastSection)?
                      'scroll up'
                      :'scroll down'
                }
              </span>
          </li>
        </ul>
        {
          (modal)?
              <div className={'modal'}>
                <div>
                  <img src={close} alt="" onClick={()=>setModal(false)}/>
                  <p>
                    Sorry for incovenience. <br/>
                    This section is under renovation.
                  </p>
                  <div>

                  </div>
                </div>
              </div>
              :null
        }
        <img className={'menu'} src={mobile} alt="menu" onClick={()=>setModal(true)}/>
        <div className={load?'mute':'mute loading'}>
          {
            mute?
                <img src={muteW} alt="" onClick={()=>setMute(false)}/>
                :
                <img src={muteOW} alt="" onClick={()=>setMute(true)}/>
          }
          <p>
            곧 영상이 플레이됩니다. <br/>
            미리 소리를 켜주세요.
          </p>
        </div>
      </div>
  )
}
export default Main

import {useOutletContext,useLocation} from "web-core/hooks"
import {useEffect} from "react";
import "../styles/about.scss"
import logo from "../assets/logo-b.svg"
import solo from "../assets/solo-exhibitions.svg"
import projects from "../assets/projects.svg"
import group from "../assets/group-exhibitions.svg"
import art from "../assets/art-fair.svg"
const About = ()=>{
    const [load,setBlack,setHeaderWhite,setLeftVisible] = useOutletContext()
    const location = useLocation()
    useEffect(()=>{
        setHeaderWhite(true)
        setBlack(true)
        setLeftVisible(false)
    },[])
    useEffect(()=>{
        if(location.hash){
            let target =document.querySelector(location.hash)
            if(target){
                let container = document.querySelector('.about')
                console.log(container)
                container.scrollTo(0,target.offsetTop-140)
            }
        }
    },[location])
    const onScroll = e=>{
        if(e.target.scrollTop>300){
            setHeaderWhite(true)
            setBlack(true)
        }else{
            setHeaderWhite(false)
            setBlack(false)
        }
    }
    const effect = ()=>{
        setHeaderWhite(false)
        setBlack(false)
    }
    return (
        <div className={'about'} onScroll={onScroll}>
            <video autoPlay src={process.env.PUBLIC_URL + '/profile.mp4#t=1'} onPlay={effect}></video>
            <section className={'white'}>
                <div>
                    <img className={'logo'} src={logo} alt="Kim Gwang Mi"/>
                    <div className={'history-box'}>
                        <img id={'solo'} className={'history'} src={solo} alt=""/>
                        <img id={'projects'} className={'history'} src={projects} alt=""/>
                    </div>
                </div>
            </section>
            <section className={'black'}>
                <div>
                    <div className={'history-box'}>
                        <img id={'group'} className={'history'} src={group} alt=""/>
                        <img id={'art'} className={'history'} src={art} alt=""/>
                    </div>
                </div>
            </section>
            <div id={'sponsor'}></div>
        </div>
    )
}
export default About

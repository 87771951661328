import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from "web-core"
import {initContext} from "web-core/functions"
import router from "./router";
import reportWebVitals from './reportWebVitals';
const routers = router
const context = initContext(routers)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App context={context} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
